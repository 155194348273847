import React, {Fragment, useEffect, useState} from "react";
import PropTypes        from "prop-types";
import {ErrorContainer} from "../dom/ErrorContainer";

const axios = require('axios').default;

export const ContractsSearchDropList = props => {

    const [errors, setErrors] = useState([]);
    const [persons, setPersons] = useState([]);
    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [query, setQuery] = useState('');
    const listItemsCount = 10;
    let moreThenTen = false;

    useEffect(() => {
        document.body.addEventListener('click', clearSearchResults);
        return () => {
            document.body.removeEventListener('click', clearSearchResults);
        };
    }, []);

    useEffect(() => {
        loadContracts();
    }, [query]);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const clearSearchResults = () => {
        setQuery('');
        setResults([]);
        setNoResults(false);
    };

    const getPersonFio = (person_id) => {
        for (let i = 0; i < persons.length; i++) {
            if (persons[i].ID === person_id) {
                return persons[i].fio;
            }
        }
        return "";
    };

    const loadContracts = () => {
        if (!query || query.length <= 1) {
            return;
        }
        axios.get('/api/contracts/', {
            params: {
                "filter": `Y`,
                "person_f": `%${query}%`,
                "limit": listItemsCount,
            },
        })
             .then((response) => {
                 setResults(response.data);
                 if (response.data.length === 0) {
                     setNoResults(true);
                 }
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
             });
    };

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            <div className="input-group">
                <input type="text"
                       value={query}
                       onChange={handleInputChange}
                       className={"form-control"}
                       placeholder={props.placeholder ? props.placeholder : "быстрый поиск"}
                       aria-label={props.placeholder ? props.placeholder : "быстрый поиск"}/>
                    <button className="btn btn-outline-secondary"
                            onClick={clearSearchResults}
                            type="button">X
                    </button>
Í            </div>
            {
                results.length ?
                    <div className={"list-group shadow entities-drop-list"}>
                        {
                            results.map((item, index) => {
                                    if (index > (listItemsCount - 1)) {
                                        moreThenTen = true;
                                        return false;
                                    }
                                    return (
                                        <a key={item.ID}
                                           href={"/contracts/" + item.ID + "/"}
                                           className={"list-group-item list-group-item-action"}
                                           title={"выбрать"}>
                                            # {item.ID}, N {item.number}, {item.student.fio}
                                        </a>
                                    );
                                },
                            )
                        }
                        {moreThenTen ?
                            <a className={'list-group-item list-group-item-action disabled text-center text-danger small'}>
                                Результатов больше чем {listItemsCount}, уточните параметры поиска
                            </a>
                            : ""}
                    </div>
                    :
                    <Fragment>
                        {noResults ?
                            <div className={"list-group shadow entities-drop-list"}>
                                <a className={'list-group-item list-group-item-action disabled text-center text-danger small'}>
                                    Ничего не найдено!
                                </a>
                            </div>
                            :
                            ""
                        }
                    </Fragment>
            }
        </Fragment>
    );
};

ContractsSearchDropList.propTypes = {
    placeholder: PropTypes.string,
};
