import React                     from 'react';
import {Pages}                   from "./Pages";
import ReactDOM                  from "react-dom";
import {ContractsSearchDropList} from "../components/ContractsSearchDropList";
import {Comments}                from "../components/Comments";

export class Contracts extends Pages{

    initPageHandlers() {

        super.initPageHandlers();
        this.data = window.diaryJsData;

        ReactDOM.render(
            <ContractsSearchDropList
                placeholder={"быстрый поиск по ФИО слушателя"}/>,
            document.getElementById('contracts-list-search-form-component'),
        );

        let commentsCells = document.querySelectorAll("[data-comments]");
        for (let i = 0; i < commentsCells.length; i++) {
            let element = commentsCells[i];
            ReactDOM.render(
                <Comments
                    entityType={element.dataset.entityType}
                    entityId={element.dataset.entityId}
                />,
                element,
            );
        }

    }
}
